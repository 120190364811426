import React from 'react'
import Header from './components/Header'
import Main from './components/Main'
import Footer from './components/Footer'

// import classes from "./App.module.css"


function App() {
      
      return (
            <>
                  <Header />
                  <Main />
                  <Footer />
            </>
      )
}

export default App