import React from "react";
import SocialMediaIcon from "./SocialMediaIcon";
import classes from "./SocialMediaBlock.module.css";

const SocialMediaBlock = ( { location, mediaTypes, description, direction, color } ) => {
	
      return (
            <section
			className={ direction === 'row' ?
                        classes.socialmediablock + ' ' + classes.row :
                              classes.socialmediablock }
		>
                  { mediaTypes.map( ( mediaType, index ) => (
                        <SocialMediaIcon
					key={ index }
					mediaType={ mediaType }
					description={ description }
                              color={ color }
                              location={ location }
				/>
                  ))}
            </section>
      );
};

export default SocialMediaBlock;
