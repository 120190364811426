import React, { useEffect, useState } from 'react';
import './ArrowAnimation.css';
import classes from './Arrow.module.css';


const Arrow = () => {
	const [ animate, setAnimate ] = useState( 'animate' );
	
	useEffect( () => {
		if( animate === 'animate' ) {
			setTimeout(() => {
				setAnimate( '' );
		
			}, 2000 );
		}
		if( animate === '' ) {
			setTimeout(() => {
				setAnimate( 'animate' );
		
			}, 2000 );
		}
	}, [ animate ]);

	return (
		<svg className={ `${ classes.arrow } ${ animate  }` } xmlns="http://www.w3.org/2000/svg" width="23" height="71" viewBox="0 0 23 71">
			<g id="Group_14" data-name="Group 14" transform="translate(-133 -370)">
				<circle id="Ellipse_1" data-name="Ellipse 1" cx="2.5" cy="2.5" r="2.5" transform="translate(142 370)" fill="#f25e48"/>
				<circle id="Ellipse_2" data-name="Ellipse 2" cx="2.5" cy="2.5" r="2.5" transform="translate(146 375)" fill="#f25e48"/>
				<circle id="Ellipse_3" data-name="Ellipse 3" cx="2.5" cy="2.5" r="2.5" transform="translate(150 381)" fill="#f25e48"/>
				<circle id="Ellipse_4" data-name="Ellipse 4" cx="2.5" cy="2.5" r="2.5" transform="translate(151 388)" fill="#f25e48"/>
				<circle id="Ellipse_5" data-name="Ellipse 5" cx="2.5" cy="2.5" r="2.5" transform="translate(135 411)" fill="#f25e48"/>
				<circle id="Ellipse_6" data-name="Ellipse 6" cx="2.5" cy="2.5" r="2.5" transform="translate(133 418)" fill="#f25e48"/>
				<circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" transform="translate(149 395)" fill="#f25e48"/>
				<circle id="Ellipse_8" data-name="Ellipse 8" cx="2.5" cy="2.5" r="2.5" transform="translate(145 401)" fill="#f25e48"/>
				<circle id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="2.5" r="2.5" transform="translate(140 406)" fill="#f25e48"/>
				<circle id="Ellipse_10" data-name="Ellipse 10" cx="2.5" cy="2.5" r="2.5" transform="translate(138 431)" fill="#f25e48"/>
				<circle id="Ellipse_11" data-name="Ellipse 11" cx="2.5" cy="2.5" r="2.5" transform="translate(134 425)" fill="#f25e48"/>
				<circle id="Ellipse_12" data-name="Ellipse 12" cx="2.5" cy="2.5" r="2.5" transform="translate(142 436)" fill="#f25e48"/>
			</g>
		</svg>
	);
};

export default Arrow;