import React from 'react';

import classes from './Flex.module.css';


const Flex = ( { children, flexStyle = 'noClass', extraClass = 'noClass' } ) => {

	return (
	     <div className={ classes.flex + ' ' + classes[ flexStyle ] + ' ' + classes[ extraClass ] }>
		     { children }
	     </div>
	);
};

export default Flex;