import React from 'react';
import ReactTooltip from "react-tooltip";
import Flex from '../Flex';
import Wrapper from '../Wrapper';

import classes from './Icons.module.css';


const Icons = () => {

	return (
		<section className={ classes.icons }>
			<Wrapper>
				<Flex flexStyle='skills'>
					<Icon dataFor='html' dataTip='HTML5' iconClassName='devicon-html5-plain' />
					<Icon dataFor='css' dataTip='CSS3' iconClassName='devicon-css3-plain' />
					<Icon dataFor='sass' dataTip='SaSS' iconClassName='devicon-sass-plain' />
					<Icon dataFor='php' dataTip='PHP' iconClassName='devicon-php-plain' />
					<Icon dataFor='wordpress' dataTip='WordPress' iconClassName='devicon-wordpress-plain' />
					<Icon dataFor='javascript' dataTip='JavaScript' iconClassName='devicon-javascript-plain' />
					<Icon dataFor='nodejs' dataTip='Node.JS' iconClassName='devicon-nodejs-plain' />
					<Icon dataFor='reactjs' dataTip='React.JS' iconClassName='devicon-react-plain' />
				</Flex>
			</Wrapper>
		</section>
	);
};

const Icon = ( { dataFor, dataTip, iconClassName } ) => {
	
	return (
		<>
			<i data-for={ dataFor } data-tip={ dataTip } className={ `${ iconClassName } ${ classes.icon }` }></i>
			<ReactTooltip
				backgroundColor='#3477C5'
				textColor='white'
				arrowColor='#F25E48'
				place="bottom"
				id={ dataFor }
				className="custom-tooltip"
			/>{" "}
		</>
		
	)
}

export default Icons;