import React from 'react';

import classes from './BreakLine.module.css';


const BreakLine = ( { position, color, sub = false } ) => {

	return (
	     <span className={ classes.breakline + ' ' + classes[ color ] + ' ' + classes[ position ] + ' ' + ( sub ? classes.sub : '' ) }></span>
	);
};

export default BreakLine;