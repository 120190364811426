import React from 'react';

import classes from './BackgroundBar.module.css';
import Button from './Button';
import Wrapper from './ui/Wrapper';


const BackgroundBar = ( { barBackgroundColor, barTextColor, buttonStyle, onClick } ) => {

	return (
		<section className={ classes.bar + ' ' + classes[ barBackgroundColor ] }>
			<Wrapper sub={ true }>
				<p className={ classes.specialtext + ' ' + classes[ barTextColor ] }>Heb jij een webdesign of wireframe klaarliggen?</p>
				<p className={ classes.specialtext + ' ' + classes[ barTextColor ] }>Zoek jij iemand wie de techniek kan verzorgen?</p>
				<p className={ classes.specialtext + ' ' + classes[ barTextColor ] }>Dan werk ik graag met jou samen.</p>
					
				<Button onClick={ onClick } marginTop={ true } bgColor={ buttonStyle } text='Bel me' />
			</Wrapper>
		</section>
	);
};

export default BackgroundBar;