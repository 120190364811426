import React from 'react';
import Wrapper from './ui/Wrapper';

import classes from './Main.module.css';

import Arrow from './ui/Arrow';
import Flex from './ui/Flex';
import BackgroundBar from './BackgroundBar';
import BreakLine from './ui/BreakLine';
import Button from './Button';
import Icons from './ui/icons/Icons';

// const Arrow = React.lazy( () => import( './ui/Arrow' ) );

const Main = () => {
	const callPhone = () => {
		window.location.href = 'tel://0624693837'
	}

	return (
		<main className={ classes.page }>
			<section className={ classes.headline }>
				<Wrapper sub={ true }>
					<h1>Zoek jij een WordPress specialist?</h1>
					<p className={ classes.backgroundtext }>Ik converteer jouw design naar een maatwerk WordPress thema waarvan de techniek klopt.</p>
				</Wrapper>
			</section>
			
			<BreakLine color='blue' sub={ true } />
			
			<section className={ classes.content }>
				<Wrapper sub={ true }>
					<Flex extraClass='explain'>
						<div className={ classes.description }>
							<h2>Van design naar techniek</h2>
							<p>
								Jij hebt een design op PDF, XD of een ander bestand en wil daar graag een website
								van laten maken?
								Dan zoek je iemand wie daarbij kan helpen en dat is niet altijd makkelijk.
								De techniek van een website kan op veel manieren worden gebouwd, maar klopt deze ook altijd?
							</p>
							<p>
								Om deze kloppend te maken is veel kennis nodig die veel verder gaat dan alleen het neer kunnen zetten van een website.
								De zoekmachines kijken of de website klopt en rekenen een slechte opbouw af met een lager resultaat binnen de zoekresultaten.
								Hier gelden veel regels en verwachtingen waar een website aan moet voldoen en het is belangrijk dat ook deze kloppen.
							</p>
							<p>
								Daarom heb ik me gespecialiseerd in het bouwen van technisch goede en snelle websites.
								Voor mij is het belangrijk om de techniek zo stabiel mogelijk op te zetten met zo weinig mogelijk plugins.
								Plugins zijn vaak niet meer dan een vertraging op de website en wanneer ik iets zelf kan bouwen, specifiek aan de wensen, kan de site optimaal blijven.
							</p>
						</div>
						
						<div className={ classes.image }>
							<img style={{ height: 350, width: 350 }} src="./images/homepageimage.webp" alt="daniel hoppener" />
							<Arrow />
							<Button onClick={ callPhone } bgColor='blue' text='Samenwerken?' />
						</div>
					</Flex>
				</Wrapper>
			</section>
			
			<BackgroundBar onClick={ callPhone } barBackgroundColor='red' barTextColor='white' buttonStyle='white' />
			
			<section className={ classes.content }>
				<Wrapper sub={ true }>
					<h2>WordPress</h2>
					<p>
						Mijn favoriete CMS systeem. Mijn jarenlange kennis van WordPress en programmeren zorgt ervoor dat ik goede,
						technisch klopppende en vooral snelle maatwerk thema's kan bouwen voor WordPress.
						Gecompliceerde problemen vind ik super om uit te zoeken. De echte breinbrekers waar geen oplossing voor lijkt te zijn,
						zijn voor mij een uitdaging.
					</p>
					<h2>WordPress en Gutenberg</h2>
					<p>
						Ik maak geen gebruik van theme builders zoals Divi of Elementor. Mijn thema's zijn volledig geprogrammeerd, regel voor regel.
						Wel maak ik gebruik van de Gutenberg editor, de blok editor van WordPress zelf.
						Deze stuur ik aan in de core door maatwerk blokken uit te denken en te programmeren die vervolgens via de Gutenberg blok 
						editor door heel de website gebruikt kunnen worden.
					</p>
					<p>
						Dit geeft veel flexibiliteit. Elk blok hoeft maar 1 keer goed uitgedacht en opgezet te worden en kan dan per pagina of post
						hergebruikt te worden. Dit geeft ook een hele schone basis voor de totale website en binnen het admin panel blijft het overzichtelijk.
						Vooral erg makkelijk te vullen voor de editors van de website.
						Ook voorkomt het dat in 1 keer allerlei info ingeladen hoeft te worden wat de website kan vertragen.
						De flexibiliteit zit hem vooral in het makkelijk kunnen uitbreiden van een website. Nieuwe wensen zijn dan makkelijk via een nieuw Gutenberg blok
						toe te voegen. Ook wijzigingen zijn makkelijker door te voeren.
					</p>
					<h2>WordPress neerzetten als een SPA?</h2>
					<p>
						Een SPA is Single Page Application. Bij traditionele websites volgt er na een klik op een knop een laadtijd en wordt de nieuwe pagina volledig ingeladen
						binnen de browser. Dat is absoluut niet slecht, maar het kan ook anders.
						Wat nu als een website aanvoelt als een mobiele telefoon app? Dat is wat een SPA doet.
						Het voelt snel en soepel aan, want er zijn geen browser refreshes meer onderhand het laden. Ook wordt alleen dat specifieke blok wat nieuw is op de pagina
						ingelagen wat de laadtijd flink verkort.
					</p>
					<p>
						Dit geeft een soepele gebruikerservaring. Wil je dus een WordPress website laten maken op die manier?
						Dan kan ik een "Headless WordPress" website voor je neerzetten en is het thema niet gebouwd in traditionele PHP code, 
						maar in React.JS
					</p>
					<h2>Heb jij een WordPress webdeveloper nodig?</h2>
					<p>
						Voor een traditionele of een Headless WordPress website?
						Mijn naam is Daniel Hoppener, ik woon in Zeeland en ik werk graag met jouw samen.
						Neem snel contact op!
					</p>
					<Button onClick={ callPhone } bgColor='blue' text='Bel mij op' />
				</Wrapper>
			</section>
			<Icons />
		</main>
	);
};

export default Main;