import React from 'react';

import classes from './Button.module.css';


const Button = ( { marginTop = false, bgColor, text, onClick } ) => {

	return (
		<button onClick={ onClick } className={ `${ classes.btn } ${ classes[ bgColor ] } ${ marginTop ? classes.marginTop : '' }` }>
			{ text }
		</button>
	);
};

export default Button;