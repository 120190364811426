import React from 'react';

import classes from './Wrapper.module.css';


const Wrapper = ( { children, sub = false } ) => {

	return (
		<div className={ ( sub ? classes.wrappersub : classes.wrapper ) }>
			{ children }
		</div>
	);
};

export default Wrapper;