import React from "react";
import SocialMediaBlock from "./ui/socialmedia/SocialMediaBlock";
import BreakLine from "./ui/BreakLine";
import Wrapper from "./ui/Wrapper";
import Flex from "./ui/Flex";
import classes from './Header.module.css';


const Header = () => {
      const mediaTypes = [ 'phone', 'mail' ]
	
      return (
            <header className={ classes.header }>
                  <Wrapper>
                        <Flex flexStyle='headerFlex'>
                              <img style={{ height: 77.31, width: 350 }} className={ classes.logo } src="./images/logo.svg" alt="" />
                              <SocialMediaBlock
                                    location='header'
                                    mediaTypes={ mediaTypes }
                                    description={ true }
                                    direction='row'
                                    color='main'
                              />
                        </Flex>
                  </Wrapper>
                  <BreakLine position='header' color='red' />
            </header>
      );
};

export default Header;
