import React from 'react'
import SocialMediaBlock from './ui/socialmedia/SocialMediaBlock';
import Wrapper from './ui/Wrapper';
import classes from './Footer.module.css'
import BreakLine from './ui/BreakLine';

const Footer = () => {
	
	const mediaTypes = [
		'facebook',
		'twitter',
		'linkedin',
		'instagram'
	]

	return (
		<footer className={ classes.footer }>
			<BreakLine position='footer' color='red' />
			<Wrapper>
				<div className={ classes.footerflex }>
					<SocialMediaBlock
						location='footer'
						mediaTypes={ mediaTypes }
						description={ true }
						direction='column'
						color='neutral'
					/>
					<div>
						<h3>Smart-<span className={ classes.blue }>Wise</span></h3>
						<p>Hyacinthenlaan 26</p>
						<p>4382 TT Vlissingen</p>
						<p>KvK: 72496525</p>
						<p>BTW: NL001751867B38</p>
					</div>
				</div>
			</Wrapper>
		</footer>
	);
};

export default Footer