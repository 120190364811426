import React from "react";
import classes from "./SocialMediaIcon.module.css";

const SocialMediaIcon = ({ mediaType, description = false, color, location, }) => {
      const svg = `./images/${mediaType}.svg`;
      const linkTo = {
            twitter: {
                  url: "https://twitter.com/DanielHoppener",
                  text: "Twitter",
                  target: '_BLANK'
            },
            linkedin: {
                  url: "https://www.linkedin.com/in/danielhoppener/",
                  text: "LinkedIn",
                  target: '_BLANK'
            },
            facebook: {
                  url: "https://www.facebook.com/daniel.hoppener",
                  text: "Facebook",
                  target: '_BLANK'
            },
            instagram: {
                  url: "https://www.instagram.com/danielhoppener/",
                  text: "Instagram",
                  target: '_BLANK'
            },
            phone: {
                  url: "tel:06-24693837",
                  text: "06-24693837",
                  target: '_SELF'
            },
            mail: {
                  url: "mailto:daniel@smart-wise.nl",
                  text: "daniel@smart-wise.nl",
                  target: '_SELF'
            },
      };
      const url = linkTo[`${mediaType}`].url;
      const text = linkTo[`${mediaType}`].text;
      const target = linkTo[`${mediaType}`].target;

      return (
            <a
                  target={ target }
                  rel="noreferrer"
                  href={url}
                  className={classes.socialmedia + " " + classes[location]}
            >
                  <span className={classes["socialmedia--icon"]}>
                        <img style={{ height: 20, width: 20 }} src={svg} alt="socialmedia" />
                  </span>
                  {description && (
                        <p
                              className={
                                    classes["socialmedia--text"] +
                                    " " +
                                    classes[color]
                              }
                        >
                              { text }
                        </p>
                  )}
            </a>
      );
};

export default SocialMediaIcon;
